import {h, Component} from 'preact';
import Modal from '../modal';
import PlusCircle from 'preact-feather/dist/icons/plus-circle';
import MinusCircle from 'preact-feather/dist/icons/minus-circle';
import Home from 'preact-feather/dist/icons/home';

export default class Hostnames extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      modalOpen: false,
      modalBusy: true,
      hostnames: [],
      defaultHostname: null,
      error: null,
      disableSave: false,
    });
    this.loadHostnames();
  }

  loadHostnames() {
    fetch(this.props.serviceUrl)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data.hostnames)) {
          throw Error();
        }
        this.setState({
          modalBusy: false,
          hostnames: data.hostnames,
          defaultHostname: data.defaultHostname,
          error: null,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          hostnames: [],
          defaultHostname: null,
          error: 'Failed to load hostnames.',
          disableSave: true,
        })
      });
  }

  cancel() {
    this.setState({modalOpen: false});
  }

  save() {
    // If data failed to load, we should not allow to save empty array
    if (this.state.disableSave) {
      return;
    }
    this.setState({
      modalBusy: true,
      error: null,
    });
    const data = {
      hostnames: this.state.hostnames,
      defaultHostname: this.state.defaultHostname,
    };
    fetch(this.props.serviceUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data.hostnames)) {
          throw Error(data.error ? data.error : 'Failed to save hostnames.');
        }
        this.setState({
          modalOpen: false,
          modalBusy: false,
          hostnames: data.hostnames,
          defaultHostname: data.defaultHostname,
        });
      })
      .catch((error) => {
        this.setState({
          modalBusy: false,
          error: error.message
        })
      });
  }

  openModal() {
    this.setState({modalOpen: true});
  }

  addHostname() {
    this.state.hostnames.push('');
    this.setState({
      hostnames: this.state.hostnames,
      error: null,
    });
  }

  removeHostname(hostname) {
    hostname = hostname.toLowerCase();
    this.setState({
      hostnames: this.state.hostnames.filter(item => item !== hostname),
      error: null,
    });
  }

  updateHostname(index, hostname) {
    hostname = hostname.toLowerCase();
    this.state.hostnames[index] = hostname;
    this.setState({
      hostnames: this.state.hostnames,
    });
  }

  setDefaultHostname(hostname) {
    this.setState({
      defaultHostname: hostname.toLowerCase(),
    });
  }

  isDefaultHostname(hostname) {
    hostname = hostname.toLowerCase();
    return hostname === this.state.defaultHostname;
  }

  render(props, state) {
    let hostnameElements = [];
    state.hostnames.forEach((hostname, index) => {
      hostnameElements.push(
          <li className="list-group-item d-flex align-items-center">
          <span className="w-100 mr-3">
              {hostname ? hostname + ' ' : <input type="text" className="form-control" onBlur={(event) => this.updateHostname(index, event.target.value)}/>}
            {this.isDefaultHostname(hostname) ? <span className="badge badge-pill badge-secondary">Default</span> : null}
          </span>
            {!this.isDefaultHostname(hostname) ? <button onClick={() => this.setDefaultHostname(hostname)} title="Set hostname as default" className="btn btn-icon mr-3"><Home/></button> : null}
            <button onClick={() => this.removeHostname(hostname)} title="Remove hostname" className="btn btn-icon"><MinusCircle/></button>
          </li>
      )
    });

    let errorElement = null;
    if (state.error) {
      errorElement = <div className="alert alert-danger">
        {state.error}
      </div>;
    }

    return <div>
      <button type="button" className="btn btn-light btn-sm" onClick={() => this.openModal()}>
        Hostnames
      </button>
      <Modal title="Hostnames"
             open={state.modalOpen}
             busy={state.modalBusy}
             disabled={state.disableSave}
             onCancel={() => this.cancel()}
             onSave={() => this.save()}
      >
        {errorElement}
        <ul className="list-group">
          {hostnameElements}
          <li className="list-group-item d-flex">
            <span className="mr-auto">&nbsp;</span>
            <button onClick={() => this.addHostname()} className="btn btn-icon"><PlusCircle/></button>
          </li>
        </ul>
      </Modal>
    </div>;
  }
}
