import {h, Component} from 'preact';
import Modal from '../modal.jsx';
import AlertCircleIcon from 'preact-feather/dist/icons/alert-circle';

export default class SmtpSetting extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      modalOpen: false,
      modalBusy: true,
      disableSave: false,
      smtpEnabled: null,
      smtpHost: null,
      smtpEncryption: null,
      smtpPort: null,
      smtpAuthentication: null,
      smtpUsername: null,
      smtpPassword: null,
      smtpDefaultFrom: null,
      smtpHasDefaultFrom: false,
      smtpForwardFrom: null,
      smtpHasForwardFrom: false,
    });
    this.loadSmtpSettings();
  }

  loadSmtpSettings() {
    fetch(this.props.serviceUrl)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          modalBusy: false,
          smtpEnabled: data.enabled,
          smtpHost: data.host,
          smtpEncryption: data.encryption,
          smtpPort: data.port,
          smtpAuthentication: data.authentication,
          smtpUsername: data.username,
          smtpPassword: data.password,
          smtpDefaultFrom: data.defaultFrom,
          smtpHasDefaultFrom: !!data.defaultFrom,
          smtpForwardFrom: data.forwardFrom,
          smtpHasForwardFrom: !!data.forwardFrom,
          error: data.error,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          error: 'Failed to load SMTP settings.',
          disableSave: true,
        })
      });
  }

  cancel() {
    this.setState({modalOpen: false});
  }

  save() {
    // If data failed to load, we should not allow to save anything
    if (this.state.disableSave) {
      return;
    }
    this.setState({
      modalBusy: true,
      error: null,
    });

    if (!this.state.smtpHasDefaultFrom) {
      this.setState({
        smtpDefaultFrom: null,
      });
    }
    if (!this.state.smtpHasForwardFrom) {
      this.setState({
        smtpForwardFrom: null,
      });
    }
    const smtpSettings = {};
    smtpSettings['enabled'] = !!this.state.smtpEnabled;
    smtpSettings['host'] = this.state.smtpHost;
    smtpSettings['encryption'] = this.state.smtpEncryption;
    smtpSettings['port'] = this.state.smtpPort;
    smtpSettings['authentication'] = !!this.state.smtpAuthentication;
    smtpSettings['username'] = this.state.smtpUsername;
    smtpSettings['password'] = this.state.smtpPassword;
    smtpSettings['defaultFrom'] = this.state.smtpDefaultFrom;
    smtpSettings['forwardFrom'] = this.state.smtpForwardFrom;
    fetch(this.props.serviceUrl, {
      method: 'POST',
      body: JSON.stringify(smtpSettings),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!data.error) {
          this.setState({
            smtpEnabled: data.enabled,
            smtpHost: data.host,
            smtpEncryption: data.encryption,
            smtpPort: data.port,
            smtpAuthentication: data.authentication,
            smtpUsername: data.username,
            smtpPassword: data.password,
            smtpDefaultFrom: data.defaultFrom,
            smtpHasDefaultFrom: !!data.defaultFrom,
            smtpForwardFrom: data.forwardFrom,
            smtpHasForwardFrom: !!data.forwardFrom,
          });
        }
        this.setState({
          modalOpen: !!data.error,
          modalBusy: false,
          error: data.error,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          error: 'Failed to save SMTP settings.'
        })
      });
  }

  openModal() {
    this.setState({modalOpen: true});
  }

  render(props, state) {
    let errorElement = null;
    if (state.error) {
      errorElement = <div className="alert alert-danger">
        {state.error}
      </div>;
    }

    const errorIcon = <AlertCircleIcon width={16} height={16} className={'ml-2'} />;

    const smtpSettingsElements = [];
    if (state.smtpEnabled) {
      smtpSettingsElements.push(
        <div className="form-group col-md-12">
          <label htmlFor="smtp_host" className="form-control-label">Host</label>
          <input id="smtp_host" type="text" className="form-control" required value={state.smtpHost}
                 onChange={(event) => this.setState({'smtpHost': event.target.value})}/>
        </div>
      );
      smtpSettingsElements.push(
        <div className="form-group col-md-12">
          <label htmlFor="smtp_encryption" className="form-control-label">Encryption</label>
          <select id="smtp_encryption" className="custom-select" value={state.smtpEncryption} onChange={(event) => this.setState({'smtpEncryption': event.target.value})}>
            <option value="">None</option>
            <option value="ssl">SSL</option>
            <option value="tls">TLS</option>
          </select>
        </div>
      );
      smtpSettingsElements.push(
        <div className="form-group col-md-12">
          <label htmlFor="smtp_port" className="form-control-label">Port</label>
          <input id="smtp_port" type="text" className="form-control" required value={state.smtpPort}
                 onChange={(event) => this.setState({'smtpPort': event.target.value})}/>
        </div>
      );
      smtpSettingsElements.push(
        <div className="form-group col-md-12 mb-2">
          <div className="custom-control custom-checkbox">
            <input id="smtp_authentication" type="checkbox" className="custom-control-input"
                   checked={state.smtpAuthentication}
                   onChange={(event) => this.setState({'smtpAuthentication': event.target.checked})}/>
            <label className="custom-control-label" htmlFor="smtp_authentication">Enable Authentication</label>
          </div>
        </div>
      );
      if (state.smtpAuthentication) {
        smtpSettingsElements.push(
          <div className="form-group col-md-12">
            <label htmlFor="smtp_username" className="form-control-label">Username</label>
            <input id="smtp_username" type="text" className="form-control" required value={state.smtpUsername}
                   onChange={(event) => this.setState({'smtpUsername': event.target.value})}/>
          </div>
        );
        smtpSettingsElements.push(
          <div className="form-group col-md-12">
            <label htmlFor="smtp_password" className="form-control-label">Password</label>
            <input id="smtp_password" type="text" className="form-control" required value={state.smtpPassword}
                   onChange={(event) => this.setState({'smtpPassword': event.target.value})}/>
          </div>
        );
      }
      smtpSettingsElements.push(
        <div className="form-group col-md-12 mb-2">
          <div className="custom-control custom-checkbox">
            <input id="smtp_has_default_from" type="checkbox" className="custom-control-input"
                   checked={state.smtpHasDefaultFrom}
                   onChange={(event) => this.setState({'smtpHasDefaultFrom': event.target.checked})}/>
            <label className="custom-control-label" htmlFor="smtp_has_default_from">Enable Default From</label>
          </div>
        </div>
      );
      if (state.smtpHasDefaultFrom) {
        smtpSettingsElements.push(
          <div className="form-group col-md-12">
            <label htmlFor="smtp_default_from" className="form-control-label">Default From</label>
            <input id="smtp_default_from" type="text" className="form-control" required value={state.smtpDefaultFrom}
                   onChange={(event) => this.setState({'smtpDefaultFrom': event.target.value})}/>
          </div>
        );
      }
      smtpSettingsElements.push(
        <div className="form-group col-md-12 mb-2">
          <div className="custom-control custom-checkbox">
            <input id="smtp_has_forward_from" type="checkbox" className="custom-control-input"
                   checked={state.smtpHasForwardFrom}
                   onChange={(event) => this.setState({'smtpHasForwardFrom': event.target.checked})}/>
            <label className="custom-control-label" htmlFor="smtp_has_forward_from">Enable Forward From</label>
          </div>
        </div>
      );
      if (state.smtpHasForwardFrom) {
        smtpSettingsElements.push(
          <div className="form-group col-md-12">
            <label htmlFor="smtp_forward_from" className="form-control-label">Forward From</label>
            <input id="smtp_forward_from" type="text" className="form-control" required value={state.smtpForwardFrom}
                   onChange={(event) => this.setState({'smtpForwardFrom': event.target.value})}/>
          </div>
        );
      }
    }

    return <div>
      <button type="button" className="btn btn-light btn-sm d-flex align-items-center" onClick={() => this.openModal()}>
        SMTP Settings {state.error ? errorIcon : null}</button>
      <Modal title="SMTP Settings"
             open={state.modalOpen}
             busy={state.modalBusy}
             disabled={state.disableSave}
             onCancel={() => this.cancel()}
             onSave={() => this.save()}
      >
        {errorElement}
        <div className="form-row">
          <div className="form-group col-md-12 mb-2">
            <div className="custom-control custom-checkbox">
              <input id="smtp_enabled" type="checkbox" className="custom-control-input" checked={state.smtpEnabled} onChange={(event) => this.setState({'smtpEnabled': event.target.checked})}/>
              <label className="custom-control-label" htmlFor="smtp_enabled">Enable</label>
            </div>
          </div>
          {smtpSettingsElements}
        </div>
      </Modal>
    </div>;
  }
}
