import {h, Component} from 'preact';
import Modal from '../modal.jsx';
import AlertCircleIcon from 'preact-feather/dist/icons/alert-circle';
import CheckIcon from 'preact-feather/dist/icons/check';
import XIcon from 'preact-feather/dist/icons/x';
import MinusCircle from "preact-feather/dist/icons/minus-circle";
import PlusCircle from "preact-feather/dist/icons/plus-circle";

export default class MediaSpaceIntegration extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      modalOpen: false,
      modalBusy: true,
      disableSave: false,
      mediaSpaceUrl: null,
      mediaSpaceValid: null,
      enableMediaSpaceSso: null,
      trustedEmailHostnames: [],
      obfuscateTrustedEmailHostnames: null,
      hostnames: [],
    });
    this.loadMediaSpaceIntegrationSettings();
  }

  loadMediaSpaceIntegrationSettings() {
    this.setState({
      loading: true,
    });
    fetch(this.props.serviceUrl)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        this.setState({
          modalBusy: false,
          mediaSpaceUrl: data.mediaSpaceUrl,
          mediaSpaceValid: data.mediaSpaceValid,
          enableMediaSpaceSso: data.enableMediaSpaceSso,
          trustedEmailHostnames: data.trustedEmailHostnames,
          obfuscateTrustedEmailHostnames: data.obfuscateTrustedEmailHostnames,
          hostnames: data.hostnames,
          loading: false,
          error: data.error,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          error: 'Failed to load MediaSpace integration settings.',
          loading: false,
          disableSave: true,
        })
      });
  }

  addTrustedEmailHostname() {
    this.state.trustedEmailHostnames.push('');
    this.setState({
      trustedEmailHostnames: this.state.trustedEmailHostnames,
      error: null,
    });
  }

  removeTrustedEmailHostname(hostname) {
    this.setState({
      trustedEmailHostnames: this.state.trustedEmailHostnames.filter(item => item !== hostname),
      error: null,
    });
  }

  updateTrustedEmailHostname(index, hostname) {
    this.state.trustedEmailHostnames[index] = hostname;
    this.setState({
      trustedEmailHostnames: this.state.trustedEmailHostnames,
    });
  }

  cancel() {
    this.setState({modalOpen: false});
  }

  save() {
    // If data failed to load, we should not allow to save anything
    if (this.state.disableSave) {
      return;
    }
    this.setState({
      modalBusy: true,
      loading: true,
      error: null,
    });
    this.state.trustedEmailHostnames = this.state.trustedEmailHostnames.filter(item => item);
    const formData = new FormData();
    formData.append('mediaSpaceUrl', this.state.mediaSpaceUrl);
    formData.append('enableMediaSpaceSso', this.state.enableMediaSpaceSso ? '1' : '0');
    formData.append('trustedEmailHostnames', JSON.stringify(this.state.trustedEmailHostnames));
    formData.append('obfuscateTrustedEmailHostnames', this.state.obfuscateTrustedEmailHostnames ? '1' : '0');
    fetch(this.props.serviceUrl, {
      method: 'POST',
      body: formData,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!data.error) {
          this.setState({
            mediaSpaceUrl: data.mediaSpaceUrl,
            mediaSpaceValid: data.mediaSpaceValid,
            enableMediaSpaceSso: data.enableMediaSpaceSso,
            trustedEmailHostnames: data.trustedEmailHostnames,
            obfuscateTrustedEmailHostnames: data.obfuscateTrustedEmailHostnames,
            hostnames: data.hostnames,
          });
        }
        this.setState({
          modalOpen: !!data.error,
          modalBusy: false,
          loading: false,
          error: data.error,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          loading: false,
          error: 'Failed to save MediaSpace integration settings.'
        })
      });
  }

  openModal() {
    if (this.state.loading) {
      return;
    }
    this.setState({modalOpen: true});
  }

  render(props, state) {
    let errorElement = null;
    if (state.error) {
      errorElement = <div className="alert alert-danger">
        {state.error}
      </div>;
    }
    const checkIcon = <CheckIcon width={16} height={16} />;
    const xIcon = <XIcon width={16} height={16} />;
    const statusIcon = MediaSpaceIntegration.getStatusIcon(state);

    let hostnameElements = [];
    state.hostnames.forEach((hostnameData, index) => {
      hostnameElements.push(
        <li className="list-group-item d-flex align-items-center">
          <span className={'d-flex mr-2'}>{hostnameData.enabled ? checkIcon : xIcon}</span>
          {hostnameData.hostname}
        </li>
      )
    });

    const hostnameSection =
      <div className="form-group col-12">
        <label className="form-control-label ml-4">SSO Enabled Hostnames</label>
        <ul className="list-group ml-4">
          {hostnameElements}
        </ul>
      </div>;

    let trustedEmailHostnameElements = [];
    state.trustedEmailHostnames.forEach((hostname, index) => {
      trustedEmailHostnameElements.push(
        <li className="list-group-item d-flex">
          <span className="w-100 mr-3">
              {hostname ? hostname : <input type="text" className="form-control" onBlur={(event) => this.updateTrustedEmailHostname(index, event.target.value)}/>}
          </span>
          <button onClick={() => this.removeTrustedEmailHostname(hostname)} className="btn btn-icon"><MinusCircle/></button>
        </li>
      )
    });

    const trustedEmailHostnamesSection =
      <div className="form-group col-12">
        <label className="form-control-label ml-4">Trusted Email Hostnames</label>
        <ul className="list-group ml-4">
          {trustedEmailHostnameElements}
          <li className="list-group-item d-flex">
            <span className="mr-auto">&nbsp;</span>
            <button onClick={() => this.addTrustedEmailHostname()} className="btn btn-icon"><PlusCircle/></button>
          </li>
        </ul>
      </div>;

    const obfuscateTrustedEmailHostnamesSection = <div className="form-group col-md-12 mb-2">
      <div className="custom-control custom-checkbox ml-4">
        <input id="obfuscate_trusted_email_hostnames" type="checkbox" className="custom-control-input"
               checked={state.obfuscateTrustedEmailHostnames}
               onChange={(event) => this.setState({'obfuscateTrustedEmailHostnames': event.target.checked})}/>
        <label className="custom-control-label" htmlFor="obfuscate_trusted_email_hostnames">Obfuscate Trusted Email
          Hostnames</label>
      </div>
    </div>;
    let mediaSpaceValid = null;
    if (state.mediaSpaceValid) {
      mediaSpaceValid = <div className="input-group-append">
        <span className="input-group-text">{checkIcon}</span>
      </div>;
    }
    return <div>
      <button type="button" className="btn btn-light btn-sm d-flex align-items-center" onClick={() => this.openModal()}>
        MediaSpace Integration {statusIcon}</button>
      <Modal title="MediaSpace Integration"
             open={state.modalOpen}
             busy={state.modalBusy}
             disabled={state.disableSave}
             onCancel={() => this.cancel()}
             onSave={() => this.save()}
      >
        {errorElement}
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="media_space_url" className="form-control-label">MediaSpace URL</label>
            <div className="input-group">
              <input id="media_space_url" type="text" className="form-control" value={state.mediaSpaceUrl} onChange={(event) => this.setState({'mediaSpaceUrl': event.target.value})}/>
              {mediaSpaceValid}
            </div>
          </div>
          <div className="form-group col-md-12 mb-2">
            <div className="custom-control custom-checkbox">
              <input id="media_space_sso" type="checkbox" className="custom-control-input" checked={state.enableMediaSpaceSso} onChange={(event) => this.setState({'enableMediaSpaceSso': event.target.checked})}/>
              <label className="custom-control-label" htmlFor="media_space_sso">Enable MediaSpace SSO</label>
            </div>
          </div>
          {state.enableMediaSpaceSso ? hostnameSection : ''}
          {state.enableMediaSpaceSso ? trustedEmailHostnamesSection : ''}
          {state.enableMediaSpaceSso ? obfuscateTrustedEmailHostnamesSection : ''}
        </div>
      </Modal>
    </div>;
  }

  static getStatusIcon(state) {
    const okIcon = <CheckIcon width={16} height={16} className={'ml-2'} />;
    const errorIcon = <AlertCircleIcon width={16} height={16} className={'ml-2'} />;
    const loadingIcon = <div className={'button-loader ml-2'} />;
    if (state.loading) {
      return loadingIcon;
    }
    else if (state.error) {
      return errorIcon;
    }
    else if (!state.mediaSpaceUrl) {
      return null;
    }
    return okIcon;
  }
}
