import {h, render} from 'preact';
import MediaSpaceIntegration from './organization/media-space-integration';
import Links from './organization/links';
import Hostnames from './organization/hostnames';
import LogoManager from './organization/logo-manager';
import SmtpSettings from './organization/smtp-settings';
import NotificationsStatus from './organization/notifications-status';
import ChangelogManager from './changelog/changelog-manager';
import {MinusIcon} from './icons';

if (typeof ROUTES.mediaSpaceIntegration !== 'undefined') {
  render(<MediaSpaceIntegration serviceUrl={ROUTES.mediaSpaceIntegration}/>, document.getElementById('media-space-integration-container'));
}

if (typeof ROUTES.hostnames !== 'undefined') {
  render(<Hostnames serviceUrl={ROUTES.hostnames}/>, document.getElementById('hostnames-container'));
}

if (typeof ROUTES.links !== 'undefined') {
  render(<Links serviceUrl={ROUTES.links}/>, document.getElementById('links-container'));
}

if (typeof ROUTES.smtpSettings !== 'undefined') {
  render(<SmtpSettings serviceUrl={ROUTES.smtpSettings}/>, document.getElementById('smtp-settings-container'));
}

if (typeof ROUTES.notificationsStatus !== 'undefined') {
  render(<NotificationsStatus serviceUrl={ROUTES.notificationsStatus} setupNotificationsUrl={ROUTES.setupNotifications}/>, document.getElementById('notifications-status-container'));
}

if (typeof ROUTES.changelog !== 'undefined' && typeof CHANGELOG !== 'undefined') {
  if (CHANGELOG) {
    CHANGELOG.releasedAt = new Date(CHANGELOG.releasedAt);
  }
  render(<ChangelogManager serviceUrl={ROUTES.changelog} changelog={CHANGELOG} token={CHANGELOG_TOKEN} errors={CHANGELOG_ERRORS}/>, document.getElementById('changelog-manager-container'));
}

if (typeof ORGANIZATION !== 'undefined') {
  render(<LogoManager title="Logo"
                      imageUrl={ORGANIZATION.logoUrl}
                      imageFileKey="logoUrl"
                      imageRemoveKey="removeLogo"
                      width="110"
                      height="30"
  />, document.getElementById('logo-manager-container'));
  render(<LogoManager title="Landing Page Logo"
                      imageUrl={ORGANIZATION.landingPageLogoUrl}
                      imageFileKey="landingPageLogoUrl"
                      imageRemoveKey="removeLandingPageLogo"
                      width="110"
                      height="30"
  />, document.getElementById('landing-page-logo-manager-container'));
  render(<LogoManager title="Favicon (.ico format)"
                      imageUrl={ORGANIZATION.faviconUrl}
                      imageFileKey="faviconUrl"
                      imageRemoveKey="removeFavicon"
                      width="32"
                      height="32"
  />, document.getElementById('favicon-manager-container'));
}

let $body = $('body');
if ($body.hasClass('route_user_edit')) {
  let $collectionHolder;
  const $addTagButton = $('<button type="button">Add Internal Tag</button>');
  $addTagButton.addClass(['btn', 'btn-light']);
  const $newLinkLi = $('<li />').addClass(['list-group-item', 'd-flex']).append($addTagButton);
  jQuery(document).ready(function() {
    $collectionHolder = $('ul.internal-tags');
    $collectionHolder.find('li').each(function() {
      addTagFormDeleteLink($(this));
    });
    $collectionHolder.append($newLinkLi);
    $collectionHolder.data('index', $collectionHolder.find(':input').length);
    $addTagButton.on('click', function(e) {
      addTagForm($collectionHolder, $newLinkLi);
    });
  });

  const addTagForm = function($collectionHolder, $newLinkLi) {
    const prototype = $collectionHolder.data('prototype');
    const index = $collectionHolder.data('index');
    var newForm = prototype;
    newForm = newForm.replace(/__name__/g, index);
    $collectionHolder.data('index', index + 1);
    const $newFormLi = $('<li />').addClass(['list-group-item', 'd-flex']).append(newForm);
    $newLinkLi.before($newFormLi);
    addTagFormDeleteLink($newFormLi);
  };

  const addTagFormDeleteLink = function($tagFormLi) {
    const $removeFormButton = $('<button class="btn btn-icon" />');
    $removeFormButton.append(render(MinusIcon));
    $tagFormLi.append($removeFormButton);
    $removeFormButton.on('click', function(e) {
      $tagFormLi.remove();
    });
  };
}
window.feather.replace();

