import {h, Component} from 'preact';
import Modal from '../modal';
import AlertCircleIcon from 'preact-feather/dist/icons/alert-circle';
import PlusCircle from 'preact-feather/dist/icons/plus-circle';
import MinusCircle from 'preact-feather/dist/icons/minus-circle';
import ChevronUp from 'preact-feather/dist/icons/chevron-up';
import ChevronDown from 'preact-feather/dist/icons/chevron-down';

export default class Links extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      modalOpen: false,
      modalBusy: true,
      links: [],
      error: null,
      disableSave: false,
    });
    this.loadLinks();
  }

  loadLinks() {
    fetch(this.props.serviceUrl)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data)) {
          throw Error();
        }
        this.setState({
          modalBusy: false,
          links: data,
          error: null,
        });
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          links: [],
          error: 'Failed to load links.',
          disableSave: true,
        })
      });
  }

  cancel() {
    this.setState({modalOpen: false});
  }

  save() {
    // If data failed to load, we should not allow to save empty array
    if (this.state.disableSave) {
      return;
    }
    this.setState({
      modalBusy: true,
      error: null,
    });
    fetch(this.props.serviceUrl, {
      method: 'POST',
      body: JSON.stringify(this.state.links)
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data)) {
          throw Error(data.error ? data.error : 'Failed to save links.');
        }
        this.setState({
          modalOpen: false,
          modalBusy: false,
          links: data,
        });
      })
      .catch((error) => {
        this.setState({
          modalBusy: false,
          error: error.message
        })
      });
  }

  openModal() {
    this.setState({modalOpen: true});
  }

  addLink() {
    this.state.links.push({
      title: '',
      url: '',
    });
    this.setState({
      links: this.state.links,
      error: null,
    });
  }

  removeLink(index) {
    const links = this.state.links;
    links.splice(index, 1);
    this.setState({
      links: links,
      error: null,
    });
  }

  moveUp(index) {
    if (index === 0) {
      return;
    }
    const links = this.state.links;
    const link = links.splice(index, 1)[0];
    links.splice(index - 1, 0, link);
    this.setState({
      links: links,
      error: null,
    })
  }

  moveDown(index) {
    if (index === this.state.links.length - 1) {
      return;
    }
    const links = this.state.links;
    const link = links.splice(index, 1)[0];
    links.splice(index + 1, 0, link);
    this.setState({
      links: links,
      error: null,
    })
  }

  updateLinkTitle(index, title) {
    this.state.links[index].title = title;
    this.setState({
      links: this.state.links,
    });
  }

  updateLinkUrl(index, url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    this.state.links[index].url = url;
    this.setState({
      links: this.state.links,
    });
  }

  render(props, state) {
    let linkElements = [];
    state.links.forEach((link, index) => {
      let linkView = null;
      if (link.title && link.url) {
        linkView = <div className="alert alert-info mb-0">{link.title + ' - ' + link.url}</div>;
      }
      else if (!link.hasOwnProperty('title') || !link.hasOwnProperty('url')) {
        linkView = <div className="alert alert-warning mb-0">Invalid Link</div>;
      }
      else {
        linkView = <div>
          <div className="form-group row">
            <label htmlFor={'title' + index} className="col-sm-1 col-form-label">Title</label>
            <div className="col-sm-11">
              <input type="text" className="form-control" id={'title' + index} onBlur={(event) => this.updateLinkTitle(index, event.target.value)} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor={'url' + index} className="col-sm-1 col-form-label">URL</label>
            <div className="col-sm-11">
              <input type="text" className="form-control" id={'url' + index} onBlur={(event) => this.updateLinkUrl(index, event.target.value)} />
            </div>
          </div>
        </div>;
      }
      linkElements.push(
        <li className="list-group-item d-flex">
          <span className="w-100 mr-3">
            {linkView}
          </span>
          <button onClick={() => this.removeLink(index)} className="btn btn-icon align-self-center mr-3"><MinusCircle/></button>
          <div className="d-flex flex-column">
            <button onClick={() => this.moveUp(index)} className="btn btn-icon"><ChevronUp/></button>
            <button onClick={() => this.moveDown(index)} className="btn btn-icon"><ChevronDown/></button>
          </div>
        </li>
      )
    });

    let errorElement = null;
    if (state.error) {
      errorElement = <div className="alert alert-danger">
        {state.error}
      </div>;
    }

    return <div>
      <button type="button" className="btn btn-light btn-sm d-flex align-items-center" onClick={() => this.openModal()}>
        Links {state.error ? <AlertCircleIcon width={16} height={16} className={'ml-2'} /> : null}
      </button>
      <Modal title="Links"
             open={state.modalOpen}
             busy={state.modalBusy}
             disabled={state.disableSave}
             onCancel={() => this.cancel()}
             onSave={() => this.save()}
      >
        {errorElement}
        <ul className="list-group">
          {linkElements}
          <li className="list-group-item d-flex">
            <span className="mr-auto">&nbsp;</span>
            <button onClick={() => this.addLink()} className="btn btn-icon"><PlusCircle/></button>
          </li>
        </ul>
      </Modal>
    </div>;
  }
}
