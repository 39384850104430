import {h, Component} from 'preact';
import PlusCircle from "preact-feather/dist/icons/plus-circle";
import MinusCircle from "preact-feather/dist/icons/minus-circle";
import ChevronUp from "preact-feather/dist/icons/chevron-up";
import ChevronDown from "preact-feather/dist/icons/chevron-down";
import {ArrowUpIcon, CircleIcon, StarIcon} from "../icons";
import ChangelogReleaseDate from "./changelog-release-date";
const moment = require('moment');

export default class ChangelogManager extends Component {
  constructor(props, context) {
    super(props, context);
    this.defaultIcons = [
      {
        'value': 'feature',
        'icon': StarIcon,
        'defaultTitle': 'New Feature',
      },
      {
        'value': 'improve',
        'icon': ArrowUpIcon,
        'defaultTitle': 'Improvements',
      },
      {
        'value': 'bug',
        'icon': CircleIcon,
        'defaultTitle': 'Bug Fixes',
      },
    ];
    if (props.changelog) {
      this.setChangelogState(props.changelog);
    }
    else {
      this.setChangelogState({
        version: null,
        releasedAt: null,
        sections: [],
      });
    }
  }

  updateChangelogProperty(property, value) {
    const changelog = this.state.changelog;
    changelog[property] = value;
    this.setChangelogState(changelog);
  }

  setChangelogState(changelog) {
    this.setState({
      'changelog': changelog,
    });
  }

  addSection() {
    const changelog = this.state.changelog;
    changelog.sections.push({
      title: null,
      icon: null,
      items: [],
    });
    this.setChangelogState(changelog);
  }

  updateSectionProperty(index, property, value) {
    const changelog = this.state.changelog;
    changelog.sections[index][property] = value;
    this.setChangelogState(changelog);
  }

  removeSection(index) {
    const changelog = this.state.changelog;
    changelog.sections.splice(index, 1);
    this.setChangelogState(changelog);
  }

  moveSectionUp(index) {
    if (index === 0) {
      return;
    }
    const changelog = this.state.changelog;
    const section = changelog.sections.splice(index, 1)[0];
    changelog.sections.splice(index - 1, 0, section);
    this.setChangelogState(changelog);
  }

  moveSectionDown(index) {
    const changelog = this.state.changelog;
    if (index === changelog.sections.length - 1) {
      return;
    }
    const section = changelog.sections.splice(index, 1)[0];
    changelog.sections.splice(index + 1, 0, section);
    this.setChangelogState(changelog);
  }

  updateSectionItem(sectionIndex, sectionItemIndex, value) {
    const changelog = this.state.changelog;
    changelog.sections[sectionIndex]['items'][sectionItemIndex] = value;
    this.setChangelogState(changelog);
  }

  addSectionItem(sectionIndex) {
    const changelog = this.state.changelog;
    const section = changelog.sections[sectionIndex];
    section.items.push(null);
    this.setChangelogState(changelog);
  }

  removeSectionItem(sectionIndex, sectionItemIndex) {
    const changelog = this.state.changelog;
    changelog.sections[sectionIndex].items.splice(sectionItemIndex, 1);
    this.setChangelogState(changelog);
  }

  updateIcon(sectionIndex, value) {
    const currentSection = this.state.changelog.sections[sectionIndex];
    const currentIconValue = currentSection.icon;
    const currentTitle = currentSection.title;
    const newIconData = this.defaultIcons.find((iconData) => iconData.value === value);
    this.defaultIcons.forEach((iconData) => {
      // If current title is the default, replace it with the default of the new icon
      if (!currentTitle || (iconData.value === currentIconValue && iconData.defaultTitle === currentTitle)) {
        currentSection.title = newIconData.defaultTitle;
      }
    });
    this.updateSectionProperty(sectionIndex, 'icon', value);
  }

  renderSection(sectionIndex, sectionData) {
    return <div>
      <div className="form-group row">
        <label htmlFor={'changelog_sections_' + sectionIndex + '_icon]'}
               className="col-sm-2 col-form-label">Icon</label>
        <div className="col-sm-10">
          {this.renderSectionIcons(sectionIndex, sectionData.icon)}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor={'changelog_sections_' + sectionIndex + '_title'}
               className="col-sm-2 col-form-label">Title</label>
        <div className="col-sm-10">
          <input
            type="text"
            id={'changelog_sections_' + sectionIndex + '_title'}
            name={'changelog[sections][' + sectionIndex + '][title]'}
            value={sectionData.title}
            onBlur={(event) => this.updateSectionProperty(sectionIndex, 'title', event.target.value)}
            className="form-control"/>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor={'changelog_sections_' + sectionIndex + '_items'}
               className="col-sm-2 col-form-label">Items</label>
        <div className="col-sm-10">
          {this.renderSectionItems(sectionIndex, sectionData.items)}
        </div>
      </div>
    </div>;
  }

  renderSectionIcons(sectionIndex, iconValue) {
    const iconElements = [];
    this.defaultIcons.forEach((iconData, index) => {
      iconElements.push(
        <span>
          <input
            type="radio"
            id={'changelog_sections_' + sectionIndex + '_icon]'}
            name={'changelog[sections][' + sectionIndex + '][icon]'}
            value={iconData.value}
            checked={iconData.value === iconValue}
            onChange={(event) => this.updateIcon(sectionIndex, event.target.value)}
          />
          {iconData.icon}
        </span>
      );
    });

    return <div className="d-flex">{iconElements}</div>
  }

  renderSectionItems(sectionIndex, sectionItems) {
    const linkElements = [];
    sectionItems.forEach((sectionItemValue, sectionItemIndex) => {
      linkElements.push(<li className="list-group-item d-flex">
          <span className="w-100 mr-3">
            <div className="col-12">
              <input
                type="text"
                name={'changelog[sections][' + sectionIndex + '][items][' + sectionItemIndex + ']'}
                className="form-control"
                value={sectionItemValue}
                onChange={(event) => this.updateSectionItem(sectionIndex, sectionItemIndex, event.target.value)}/>
            </div>
          </span>
          <button type="button" onClick={() => this.removeSectionItem(sectionIndex, sectionItemIndex)}
                  className="btn btn-icon align-self-center"><MinusCircle/>
          </button>
      </li>);
    });
    return <ul className="list-group">
      {linkElements}
      <li className="list-group-item d-flex">
        <span className="mr-auto">&nbsp;</span>
        <button type="button" onClick={() => this.addSectionItem(sectionIndex)} className="btn btn-icon"><PlusCircle/></button>
      </li>
    </ul>
  }

  renderErrors() {
    if (this.props.errors) {
      const errors = [];
      this.props.errors.forEach((error) => {
        errors.push(<div className="alert alert-danger">{error}</div>);
      });
      return <div>{errors}</div>;
    }
  }

  render(props, state) {
    let sectionElements = [];
    state.changelog.sections.forEach((sectionData, sectionIndex) => {
      sectionElements.push(
        <li className="list-group-item d-flex">
          <span className="w-100 mr-3">
            {this.renderSection(sectionIndex, sectionData)}
          </span>
          <button type="button" onClick={() => this.removeSection(sectionIndex)} className="btn btn-icon align-self-center mr-3">
            <MinusCircle/></button>
          <div className="align-self-center d-flex flex-column">
            <button type="button" onClick={() => this.moveSectionUp(sectionIndex)} className="btn btn-icon"><ChevronUp/></button>
            <button type="button" onClick={() => this.moveSectionDown(sectionIndex)} className="btn btn-icon"><ChevronDown/></button>
          </div>
        </li>
      )
    });

    return <div>
      <form name="changelog" method="post">
        <div className="form-row">
          {this.renderErrors()}
        </div>
        <div className="form-row">
          <div className="form-group col-xl-10">
            <label htmlFor="changelog_version" className="form-control-label required">Version</label>
            <input
              type="text"
              id="changelog_version"
              name="changelog[version]"
              required="required"
              className="form-control"
              value={state.changelog.version}
              onChange={(event) => this.updateChangelogProperty('version', event.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="changelog_released_at" className="form-control-label required">Release Date</label>
            <ChangelogReleaseDate
              value={state.changelog.releasedAt}
              onChange={(value) => this.updateChangelogProperty('releasedAt', value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-xl-10">
            <label htmlFor="changelog_version" className="form-control-label required">Sections</label>
            <ul className="list-group">
              {sectionElements}
              <li className="list-group-item d-flex">
                <span className="mr-auto">&nbsp;</span>
                <button type="button" onClick={() => this.addSection()} className="btn btn-icon"><PlusCircle/></button>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <input type="hidden" name="changelog[_token]" value={props.token}/>
          <button
            type="submit"
            name="changelog[save]"
            className="btn-secondary btn">Save
          </button>
        </div>
      </form>
    </div>;
  }
}
