import {h} from 'preact';

const StarIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-large">
  <path
    d="M11.7207953,17.1467866 L7.19823077,19.5244395 C6.90492402,19.67864 6.54214772,19.5658721 6.38794724,19.2725653 C6.3265438,19.155769 6.30535499,19.0219882 6.3276611,18.8919336 L7.19139407,13.8559839 C7.22477683,13.6613475 7.16024774,13.4627474 7.01883609,13.3249051 L3.36000451,9.75842575 C3.12271436,9.52712503 3.11785898,9.14725707 3.3491597,8.90996692 C3.44126487,8.81547674 3.56195031,8.75398444 3.69253253,8.73500973 L8.74891342,8.00027458 C8.94433952,7.9718775 9.11327887,7.84913588 9.20067608,7.6720498 L11.4619584,3.09019145 C11.6086117,2.79303925 11.9683872,2.67103586 12.2655394,2.81768923 C12.383867,2.87608737 12.4796435,2.97186389 12.5380416,3.09019145 L14.7993239,7.6720498 C14.8867211,7.84913588 15.0556605,7.9718775 15.2510866,8.00027458 L20.3074675,8.73500973 C20.6353944,8.7826603 20.8626033,9.08712601 20.8149527,9.41505293 C20.795978,9.54563515 20.7344857,9.66632059 20.6399955,9.75842575 L16.9811639,13.3249051 C16.8397523,13.4627474 16.7752232,13.6613475 16.8086059,13.8559839 L17.6723389,18.8919336 C17.7283555,19.2185355 17.5090028,19.528709 17.1824009,19.5847256 C17.0523464,19.6070317 16.9185655,19.5858429 16.8017692,19.5244395 L12.2792047,17.1467866 C12.1044103,17.0548917 11.8955897,17.0548917 11.7207953,17.1467866 Z"
    id="Star" fill="currentColor" fill-rule="nonzero"></path>
</svg>;

const ArrowUpIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-large">
  <path
    d="M14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,11 L7.2,11 C6.86862915,11 6.6,10.7313708 6.6,10.4 C6.6,10.2701779 6.64210672,10.1438577 6.72,10.04 L11.52,3.64 C11.7188225,3.37490332 12.0949033,3.32117749 12.36,3.52 C12.4054834,3.55411255 12.4458875,3.5945166 12.48,3.64 L17.28,10.04 C17.4788225,10.3050967 17.4250967,10.6811775 17.16,10.88 C17.0561423,10.9578933 16.9298221,11 16.8,11 L14,11 Z"
    id="Combined-Shape" fill="currentColor" fill-rule="nonzero"></path>
</svg>

const CircleIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-large">
  <circle id="Oval" fill="currentColor" fill-rule="nonzero" cx="12" cy="12" r="6"></circle>
</svg>;

const MinusIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-large">
  <circle cx="12" cy="12" r="10"></circle>
  <line x1="8" y1="12" x2="16" y2="12"></line>
</svg>;

export {StarIcon, ArrowUpIcon, CircleIcon, MinusIcon}
