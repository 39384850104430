import {h, Component} from 'preact';
import {PropTypes} from 'prop-types';
const moment = require('moment');

export default class ChangelogReleaseDate extends Component {
  constructor(props, context) {
    super(props, context);
    this.onYearChange = this.onYearChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.years = [];
    for (let year = 2018; year <= moment().year(); year++) {
      this.years.push(year);
    }

    if (this.props.value) {
      this.setDateState(this.props.value);
    }
    else {
      this.setDateState(new Date());
      const moment = this.getCurrentMoment();
      this.props.onChange(moment.toDate());
    }
  }

  setDateState(date) {
    this.setState({'value': date});
    this.days = [];
    for(let day = 1; day <= this.getCurrentMoment().daysInMonth(); day++) {
      this.days.push(day);
    }
  }

  getCurrentMoment() {
    return moment(this.state.value);
  }

  getCurrentYear() {
    const moment = this.getCurrentMoment();
    return moment.year();
  }

  getCurrentMonth() {
    const moment = this.getCurrentMoment();
    return moment.month() + 1;
  }

  getCurrentDate() {
    const moment = this.getCurrentMoment();
    return moment.date();
  }

  onYearChange(event) {
    const moment = this.getCurrentMoment();
    moment.year(event.target.value);
    this.setDateState(moment.toDate());
    this.props.onChange(moment.toDate());
  }

  onMonthChange(event) {
    const moment = this.getCurrentMoment();
    moment.month(event.target.value - 1);
    this.setDateState(moment.toDate());
    this.props.onChange(moment.toDate());
  }

  onDayChange(event) {
    const moment = this.getCurrentMoment();
    moment.date(event.target.value);
    this.setDateState(moment.toDate());
    this.props.onChange(moment.toDate());
  }

  renderYears() {
    let optionsElements = [];
    const currentYear = this.getCurrentYear();
    this.years.forEach((year) => {
      optionsElements.push(<option value={year} selected={year === currentYear}>{year}</option>);
    });
    return <select className="form-control" style="width: auto" name="changelog[releasedAt][year]" onChange={this.onYearChange}>{optionsElements}</select>;
  }

  renderMonths() {
    let optionsElements = [];
    const currentMonth = this.getCurrentMonth();
    this.months.forEach((month) => {
      optionsElements.push(<option value={month} selected={month === currentMonth}>{month}</option>);
    });
    return <select className="form-control" style="width: auto" name="changelog[releasedAt][month]" onChange={this.onMonthChange}>{optionsElements}</select>;
  }

  renderDays() {
    let optionsElements = [];
    const currentDay = this.getCurrentDate();
    this.days.forEach((day) => {
      optionsElements.push(<option value={day} selected={day === currentDay}>{day}</option>);
    });
    return <select className="form-control" style="width: auto" name="changelog[releasedAt][day]" onChange={this.onDayChange}>{optionsElements}</select>;
  }

  render(props, state) {
    const years = this.renderYears();
    const months = this.renderMonths();
    const days = this.renderDays();
    return <span className="d-flex">{years}{months}{days}</span>;
  }
}

Date.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.date,
  onChange: PropTypes.func,
};
