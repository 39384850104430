import {h, Component} from 'preact';
import Portal from 'preact-portal';

export default class Modal extends Component {
  constructor() {
    super();
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        document.body.classList.add('modal-open');
      }
      else {
        document.body.classList.remove('modal-open');
      }
    }
  }

  onSaveClick() {
    if (this.formRef.checkValidity()) {
      this.props.onSave();
    }
  }

  onFormSubmit(event) {
    event.preventDefault();
  }

  render(props, state) {
    const title = <div className="modal-header">
      <h5 className="modal-title">{props.title}</h5>
      <button type="button" className="close" onClick={props.onCancel}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>;
    return <Portal into="body">
      <div>
        <div className={'modal ' + (props.open ? 'd-block' : '')} tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form ref={ref => this.formRef = ref} onSubmit={this.onFormSubmit} >
                {title ? title : null}
                <div className="modal-body">
                  {props.children}
                </div>
                <div className="modal-footer">
                  <button type="submit" className={'btn btn-primary ' + (props.disabled ? 'disabled' : '')} onClick={this.onSaveClick}>Save</button>
                  <button type="button" className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>
                </div>
                <div className={props.busy ? "modal-busy" : ''}/>
              </form>
            </div>
          </div>
        </div>
        <div className={'modal-backdrop ' + (props.open ? 'fade show' : 'd-none')} onClick={props.onCancel}/>
      </div>
    </Portal>
  }
}
