import {h, Component} from 'preact';
import Modal from '../modal.jsx';
import AlertCircleIcon from 'preact-feather/dist/icons/alert-circle';
import CheckIcon from 'preact-feather/dist/icons/check';

export default class NotificationsStatus extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      modalOpen: false,
      disableSave: false,
      error: false,
    });
    this.loadNotificationsStatus();
  }

  loadNotificationsStatus() {
    this.setState({
      loading: true,
    });
    fetch(this.props.serviceUrl)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (!data) {
          throw Error();
        }
        const hasInvalidNotification = this.hasInvalidNotificationStatus(data);
        this.setState({
          loading: false,
          list: data,
          error: hasInvalidNotification ? 'At least one notification is not setup correctly, click save to fix.' : null,
        });
      })
      .catch(() => {
        this.setState({
          error: 'Failed to load notifications statuses.',
          loading: false,
          disableSave: true,
        })
      });
  }

  hasInvalidNotificationStatus(list) {
    return Object.keys(list).some((key) => {
      if (!list[key]) {
        return true;
      }
    });
  }

  cancel() {
    this.setState({modalOpen: false});
  }

  setupNotifications() {
    if (this.state.disableSave) {
      return;
    }

    this.setState({
      modalBusy: true,
      loading: true,
      error: null,
    });

    fetch(this.props.setupNotificationsUrl, {
      method: 'GET'
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data) {
          this.setState({
            modalBusy: false,
            modalOpen: false,
            loading: false,
          });
          this.loadNotificationsStatus();
        }
        else {
          throw Error();
        }
      })
      .catch(() => {
        this.setState({
          modalBusy: false,
          loading: false,
          error: 'Failed to setup notifications.'
        })
      });
  }

  openModal() {
    if (this.state.loading) {
      return;
    }
    this.setState({modalOpen: true});
  }

  render(props, state) {
    let errorElement = null;
    if (state.error) {
      errorElement = <div className="alert alert-danger">
        {state.error}
      </div>;
    }
    const okIcon = <CheckIcon width={16} height={16} />;
    const alertIcon = <AlertCircleIcon width={16} height={16} />;
    const statusIcon = NotificationsStatus.getStatusIcon(state);

    const trElements = [];
    if (state.list) {
      Object.keys(state.list).forEach((key) => {
        let trElement = <tr>
          <td>{key}</td>
          <td>{state.list[key] ? okIcon : alertIcon}</td>
        </tr>;
        trElements.push(trElement);
      });
    }

    return <div>
      <button type="button" className="btn btn-light btn-sm d-flex align-items-center" onClick={() => this.openModal()}>
        Notifications Status {statusIcon}</button>
      <Modal title="Notifications Status"
             open={state.modalOpen}
             busy={state.modalBusy}
             disabled={state.disableSave}
             onCancel={() => this.cancel()}
             onSave={() => this.setupNotifications()}
      >
        {errorElement}
        <div className="form-row">
          <table className="table table-bordered">
            <tr>
              <th>Notification</th>
              <th>Status</th>
            </tr>
            {trElements}
          </table>
        </div>
      </Modal>
    </div>;
  }

  static getStatusIcon(state) {
    const okIcon = <CheckIcon width={16} height={16} className={'ml-2'} />;
    const errorIcon = <AlertCircleIcon width={16} height={16} className={'ml-2'} />;
    const loadingIcon = <div className={'button-loader ml-2'} />;
    if (state.loading) {
      return loadingIcon;
    }
    else if (state.error) {
      return errorIcon;
    }
    return okIcon;
  }
}
