import {h, Component} from 'preact';

export default class LogoManager extends Component {
  constructor(props, context) {
    super(props, context);
    this.setState({
      imageUrl: props.imageUrl,
      removeImage: false,
    })
  }

  removeImage() {
    this.setState({
      imageUrl: null,
      removeImage: true,
    });
  }


  render(props, state) {
    let logoView = null;
    const imageUrl = state.imageUrl;
    const removeValue = state.removeImage;
    const imageFileKey = props.imageFileKey;
    const imageRemoveKey = props.imageRemoveKey;
    if (imageUrl) {
      const imageUrlSuffix = (props.width && props.height) ? ('/width/' + props.width + '/height/' + props.height + '/type/2') : '';
      const imageStyle = {
        width:  props.width + 'px',
        height: props.height + 'px',
      };
      logoView = <div className="d-flex">
        <img src={imageUrl + imageUrlSuffix} width={props.width} height={props.height} style={imageStyle} className="organization-image img-thumbnail d-flex"/>
        <button type="button" className="btn btn-icon m-2" onClick={() => this.removeImage()}>
          <span data-feather="x-circle"></span></button>
      </div>;
    }
    else {
      logoView = <div>
        <input type="file" id={imageFileKey} name={imageFileKey} className="form-control-file"/>
        <input type="hidden" id={imageRemoveKey} name={imageRemoveKey} className="form-control-file"
               value={removeValue}/>
      </div>;
    }

    return <div>
      <label htmlFor={imageFileKey} className="form-control-label">{props.title}</label>
      {logoView}
    </div>
      ;
  }
}
